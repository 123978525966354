import { API } from 'utils/api';

export const login = async (data) => {
	const res = await API.post('rest/v1/sessions/login', data);
	return res.data;
};

export const tmoreLogin = async (data) => {
	const res = await API.post('rest/v1/sessions/teachmore/login', data);
	return res.data;
};
